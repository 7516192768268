.btn-submit {
  width: 11rem;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background-color: #2d418c;
  padding: 12px 16px;
}

.btn-accent {
  width: 12rem;
  font-size: 15px;
  background-color: #b3c0ff;
  color: #000000;
  text-transform: capitalize;
  border: 1.2px solid #b3c0ff;
  border-radius: 4px;
  padding: 12px 16px;
}

.header-btn-accent:hover {
  background-color: #93a1e9;
  border: 1.2px solid #93a1e9;
  color: #fff;
}

.header-btn {
  width: 10rem;
  text-align: center;
  font-size: 15px;
  color: #000000;
  text-transform: capitalize;
  border: 1.2px solid #93a1e9;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 16px;
}

.header-btn:hover {
  border-color: #2d418c;
}

.submit-form-btn {
  display: inline-block;
  position: relative;
  width: 11rem;
  left: 0;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 16px;
  border: none;
  background-color: #2d418c;
  margin-top: 5vh;
}
