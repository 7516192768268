.icon {
  display: block;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-size: cover;
}

.icon:hover {
  opacity: 0.85;
}

@media screen and (max-width: 870px) {
  .icon {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
}
