footer{
    display: inline-block;
    min-height: 8vh;
    padding: 20px 8% 20px 8%;
    width: 100%;
    background-color: #080d24;
}

.container-block {
    display: flex;
    position: relative;
    min-height: auto;
    padding: 50px 35px;
    width: 100%;
    overflow: hidden;
}

.copyright-part{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
    text-align: center;
    min-height: 5vh;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding-left: 3%;
    padding-right: 3%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-height: 400px;
    padding: 0px 25px;
}

.contact-info span:first-child{
    color: #fff;
    font-family: "Playfair Display", serif;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.contact-info span:nth-child(2){
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: #cdd5ff;
    font-family: "Open Sans", sans-serif;
}

.contact-info span p a{
    color: #ffcc3e;
    font-weight: 300;
}
.contact-info span p a:hover{
    text-decoration: underline;
}
.footer-map{
    width:60%;
    position: relative;
    overflow: hidden;
}

@media screen and (max-width:1365px){
    .footer-map{
        height: 350px;
    }
    .contact-info {
        width: 25%;
        min-height: 400px;
        padding: 0px 10px;
    }
}

@media screen and (max-width:1255px){
    footer{
        padding: 20px 5% 20px 5%;
    }
}

@media screen and (max-width:980px){
    footer {
        padding: 15px 0px 15px 0px;
    }
    footer .container-block{
        flex-direction: column;
    }
    .contact-info {
        width: 100%;
        min-height: auto;
        padding: 25px 0px 25px 0px;
        text-align: center;
    }
    .footer-map {
        width: 110%;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: -5%;
    }
}

@media screen and (max-width:1210px){
    .container-block{
        padding: 20px 5%;
    }
}