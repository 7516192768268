@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

@import 'lightgallery.js/dist/css/lightgallery.css';

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
