nav {
  z-index: 222;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  min-height: 4.5em;
  background-color: #fffffffa;
  font-family: 'Open Sans', sans-serif;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: 0px 0px 8px 0px #00000026;
}

.header-logo {
  display: inline-block;
  font-family: 'Playfair Display', serif;
}

.header-logo a {
  text-decoration: none;
}

.header-logo a span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 22px;
  color: #000000;
}

.header-logo a img {
  height: 55px;
  width: 55px;
  margin-left: 5px;
  margin-right: 5px;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  margin-top: 0;
  margin-bottom: 0;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  position: relative;
  z-index: 3;
  color: #000000;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
}

.nav-links a:hover {
  color: #2d418c;
}

.nav-links button {
  position: relative;
  z-index: 3;
  color: #000000;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  border: none;
  background-color: #ffffff00;
}

.nav-links button:hover {
  color: #2d418c;
}

.nav-button {
  border: 1.2px solid #cdd5ff;
  background: #cdd5ff;
  border-radius: 4px;
  padding: 12px 16px;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 0px 8px 0px #00000026;
  animation: slide-down 0.8s;
  z-index: 999;
}

ul {
  padding: 0;
  list-style: none;
}
ul li {
  display: inline-block;
  position: relative;
  line-height: 21px;
  text-align: left;
}
.nav-links ul li a {
  display: block;
  padding: 8px 25px 8px 25px;
  color: #4d4d4d;
  background-color: #f5f6ff;
  text-decoration: none;
}
.nav-links ul li a:hover {
  color: #2d418c;
  background: #c7d1ff;
}
.nav-links li ul.dropdown {
  min-width: 100%;
  height: 100%;
  background: none;
  display: none;
  position: absolute;
  z-index: 9999;
  padding-top: 2.8vh;
  left: 0;
}

.nav-links li ul.dropdown li:last-child {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 0px;
}

.dropdown-hover:hover .dropdown {
  display: block;
}

.nav-links li ul.dropdown li {
  display: block;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.body-shadow {
  display: block;
  position: fixed;
  z-index: 6;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #00000069;
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
}

.priority {
  border-color: #b3c0ff;
  background-color: #ffffff !important;
}

.priority:hover {
  background: #b3c0ff !important;
}

.nav-button:hover {
  background: #b3c0ff;
}

.fa-angle-down,
.fa-sign-in {
  margin-left: 5px;
}

.priority .fa-sign-in:hover {
  color: #93a1e9;
}

.fa-sign-in:hover {
  color: #93a1e9 !important;
}

.fa-sign-in {
  color: #b3c0ff;
}

.fa-lock {
  color: #b3c0ff;
  margin: 0 8px;
}

.fa-lock:hover {
  color: #93a1e9;
}

.burger {
  display: none;
  cursor: pointer;
  margin-right: 25px;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #2d418c;
  margin: 5px;
  transition: all 0.3s ease;
}

.responsive-login-button {
  display: block;
}

.responsive-login-button a {
  color: #b3c0ff;
}

.responsive-login-button .fa-sign-in {
  display: inline-block;
}

.mob {
  display: none;
}
.large-screen {
  display: block;
}

.icon {
  display: block;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-size: cover;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 1556px) {
  .nav-links {
    width: 50%;
  }
}

@media screen and (max-width: 1240px) {
  .responsive-login-button {
    display: inline-block;
  }
  .nav-button {
    display: none;
  }
  .nav-links {
    width: 60%;
  }
  .large-screen {
    display: none;
  }
}

@media screen and (max-width: 998px) {
  .nav-links {
    width: 70%;
  }
}

@media screen and (max-width: 870px) {
  body {
    overflow-x: hidden;
  }
  nav {
    min-height: 60px;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }
  .nav-links {
    position: fixed;
    z-index: 111;
    height: calc(100vh - 60px);
    top: 60px;
    right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 80%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    border-top: 1.2px solid #2d418c;
  }
  .nav-links li {
    margin: 18px 8% 18px 8%;
  }
  .nav-links ul li a {
    padding: 10px 25px 10px 2%;
  }
  .nav-links li ul.dropdown li:last-child {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 1em;
  }
  .burger {
    display: block;
    margin-right: 0;
  }
  .fa-angle-down:before {
    content: '\f105';
  }
  .mobile-dropdown {
    position: fixed;
    z-index: 6666;
    height: 100vh;
    top: 0;
    background-color: #fff;
    display: inline-block;
    align-items: center;
    min-width: 100% !important;
    padding: 0 10% 0 10%;
    transition: transform 0.5s ease-in;
  }
  .mobile-dropdown .dropdown {
    display: block !important;
  }
  .mobile-dropdown a {
    font-size: 18px;
  }
  .mobile-dropdown li a {
    font-size: 15px;
  }
  .dropdown-hover:hover .dropdown {
    display: none;
  }
  ul .dropdown {
    display: block;
  }
  .nav-links li ul.dropdown {
    padding-top: 0px;
  }
  .hidden-arrow:before {
    content: '\f104';
  }
  .article-share {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b355d;
    height: calc(18vh + 70px);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 70px;
    bottom: 0;
    left: 0;
  }
  .article-share li span a {
    background-color: #2d418c;
  }
  .large-screen {
    display: none !important;
  }
  .mob {
    display: flex;
  }
  .menu-scroll {
    overflow-y: hidden !important;
  }
  .icon {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
  .contact-form {
    bottom: 3%;
    right: 5%;
  }
  .article-share-mob-icon {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
  }
  .article-share span {
    display: inline-block;
    color: #e3e3e3;
    text-align: center;
    font-size: 14px;
    margin: 0 5px;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
