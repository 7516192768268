@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap);
html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  border-style: none;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f4ff;
}

::-webkit-scrollbar-thumb {
  background: #cdd5ff;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3c0ff;
}

*,
::after,
::before {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  background-color: transparent;
}

body {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title-container {
  text-align: center;
  padding: 50px 0 15px 0;
  margin-bottom: 20px;
}

.title-container span:first-child {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
}

.title-container span:nth-child(2) {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: #000;
}

.wrap-login100 {
  position: relative;
  border-bottom: 2px solid #b3c0ff;
  margin-top: 25px;
  margin-bottom: 25px;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #b3c0ff;
  margin-bottom: 45px;
}

.input100 {
  font-size: 18px;
  color: #ffffff;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 52px;
  background: transparent;
  padding: 0 5px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;

  background: #ffffff;
}

.focus-input100::after {
  font-size: 18px;
  color: #b3c0ff;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

@media screen and (max-width: 586px) {
  .title-container {
    padding-bottom: 2em;
    padding-top: 2em;
    text-align: center;
  }
  .title-container span:first-child {
    font-size: 30px;
    line-height: 48px;
  }
  .title-container span:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
  }
}

.btn-submit {
  width: 11rem;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background-color: #2d418c;
  padding: 12px 16px;
}

.btn-accent {
  width: 12rem;
  font-size: 15px;
  background-color: #b3c0ff;
  color: #000000;
  text-transform: capitalize;
  border: 1.2px solid #b3c0ff;
  border-radius: 4px;
  padding: 12px 16px;
}

.header-btn-accent:hover {
  background-color: #93a1e9;
  border: 1.2px solid #93a1e9;
  color: #fff;
}

.header-btn {
  width: 10rem;
  text-align: center;
  font-size: 15px;
  color: #000000;
  text-transform: capitalize;
  border: 1.2px solid #93a1e9;
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 16px;
}

.header-btn:hover {
  border-color: #2d418c;
}

.submit-form-btn {
  display: inline-block;
  position: relative;
  width: 11rem;
  left: 0;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 16px;
  border: none;
  background-color: #2d418c;
  margin-top: 5vh;
}

.BaseIcon_icon__2oIah {
  display: block;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-size: cover;
}

.BaseIcon_icon__2oIah:hover {
  opacity: 0.85;
}

@media screen and (max-width: 870px) {
  .BaseIcon_icon__2oIah {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
}

.ContactForm_article-share__1YINt {
  position: fixed;
  z-index: 444;
  bottom: 58vh;
  right: 1.5vh;
  display: flex;
  flex-direction: column;
}
.ContactForm_mob__10UAd {
  display: none;
}

.ContactForm_article-share__1YINt li span a {
  background-color: #2d418c;
}

.ContactForm_large-screen__1wOJA {
  display: block;
}

.ContactForm_article-share__1YINt span {
  margin-bottom: 5px;
}

.ContactForm_icon__1TiGo {
  display: block;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-size: cover;
}
.ContactForm_icon-facebook__Eoazo {
  background-image: url(/static/media/facebook.c799c639.svg);
}
.ContactForm_icon-facebook__Eoazo:hover {
  opacity: 0.85;
}
.ContactForm_icon-mail__VgFWu {
  background-image: url(/static/media/mail.c57d4a7c.svg);
}
.ContactForm_icon-mail__VgFWu:hover {
  opacity: 0.85;
}
.ContactForm_icon-instagram__4akro {
  background-image: url(/static/media/instagram.bb513ccf.svg);
}
.ContactForm_icon-instagram__4akro:hover {
  opacity: 0.85;
}
.ContactForm_icon-telegram__1IkrZ {
  background-image: url(/static/media/telegram.a6b2b9a5.svg);
}
.ContactForm_icon-telegram__1IkrZ:hover {
  opacity: 0.85;
}
.ContactForm_icon-youtube__2Nfq9 {
  background-image: url(/static/media/youtube.6aabbcfc.svg);
}
.ContactForm_icon-youtube__2Nfq9:hover {
  opacity: 0.85;
}

.ContactForm_contact-form-icon__3HcUH {
  display: block;
  width: 54px;
  height: 54px;
  cursor: pointer;
  background-size: cover;
}

.ContactForm_contact-form__osuFe {
  display: block !important;
  position: fixed;
  z-index: 100;
  bottom: calc(58vh - 55px);
  right: 1vh;
  height: 54px;
  width: 54px;
  text-align: center;
}
.ContactForm_contact-form-header__12HTE img {
  height: 65px;
  width: 65px;
  margin-right: 10px;
}
.ContactForm_contact-form-block__2Llff {
  position: fixed;
  display: block;
  z-index: 444;
  bottom: calc(13vh - 80px);
  right: 1.5vh;
  height: auto;
  width: 350px;
  background-color: #fff;
  border-radius: 50px 5px 50px 5px;
  -webkit-animation: ContactForm_slide-left__3fzi_ 0.6s;
          animation: ContactForm_slide-left__3fzi_ 0.6s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 8px;
}

.ContactForm_contact-form-container__1jGat {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 25px 15px 25px;
}

.ContactForm_contact-form-header__12HTE {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.ContactForm_contact-form-header__12HTE span {
  font-size: 18px;
  margin: 0 0;
  color: #000000;
}

.ContactForm_contact-form-header-subtitle__LlXO1 {
  font-size: 15px;
  color: #a1a1a1;
  margin: 10px 0 10px 0;
}

.ContactForm_contact-form-body__27cH9 {
  margin: 15px 0px 10px 0px;
  position: relative;
}

.ContactForm_form-group__dnvR8 {
  margin-bottom: 1.5rem;
  position: relative;
}

.ContactForm_form-group__dnvR8 input {
  border: 1.2px solid #93a1e9;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  padding: 15px 23px 5px 23px;
  text-transform: lowercase;
}

.ContactForm_form-group__dnvR8 input:focus {
  border: 1.2px solid #2d418c;
}

.ContactForm_form-group__dnvR8 label {
  position: absolute;
  text-align: left;
  bottom: 0px;
  left: 15px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  pointer-events: none;
}

.ContactForm_form-group__dnvR8 label span {
  color: #93a1e9;
  padding: 0px 8px;
  margin: 0px 0px 0px 0px;
  background-color: #fff;
}

.ContactForm_form-group__dnvR8 label::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.ContactForm_content-name__2srkz {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease 0s;
}

.ContactForm_loginForm__2jXWB {
  display: flex;
  flex-direction: column;
  min-height: 120px;
}

.ContactForm_loginForm__2jXWB input {
  color: #2d418c;
}

.ContactForm_loginForm__2jXWB span::before {
  background: #2d418c;
}

.ContactForm_wrap-login__1gAkN {
  position: relative;
  border-bottom: 2px solid #b3c0ff;
  margin-top: 15px;
  margin-bottom: 25px;
}

.ContactForm_form-group__dnvR8 input:focus + .ContactForm_label-name__3uUY0 .ContactForm_content-name__2srkz,
.ContactForm_form-group__dnvR8 input:valid + .ContactForm_label-name__3uUY0 .ContactForm_content-name__2srkz {
  color: #2d418c;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.ContactForm_form-icon__3njFh {
  background-image: url(/static/media/chat.68457e6d.svg);
}
.ContactForm_form-icon__3njFh:hover {
  opacity: 0.85;
}
.ContactForm_form-close__3l4oy {
  cursor: pointer;
}
.ContactForm_form-close__3l4oy div {
  width: 25px;
  height: 3px;
  background-color: #565656;
  margin: 5px;
}

.ContactForm_form-line1__2YQbe {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
          transform: rotate(-45deg) translate(-5px, 6px);
}

.ContactForm_form-line2__314IV {
  opacity: 0;
}

.ContactForm_form-line3__3wl2r {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
          transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 870px) {
  body {
    overflow-x: hidden;
  }
  nav {
    min-height: 60px;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }
  .ContactForm_nav-links__1QIPx {
    position: fixed;
    z-index: 111;
    height: calc(100vh - 60px);
    top: 60px;
    right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 80%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
    border-top: 1.2px solid #2d418c;
  }
  .ContactForm_nav-links__1QIPx li {
    opacity: 0;
    margin: 18px 8% 18px 8%;
  }
  .ContactForm_nav-links__1QIPx ul li a {
    padding: 10px 25px 10px 2%;
  }
  .ContactForm_nav-links__1QIPx li ul.ContactForm_dropdown__1qz7k li:last-child {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 1em;
  }
  .ContactForm_burger__3qU3c {
    display: block;
    margin-right: 0;
  }
  .ContactForm_fa-angle-down__3laKP:before {
    content: '\f105';
  }
  .ContactForm_mobile-dropdown__9CQjN {
    position: fixed;
    z-index: 6666;
    height: 100vh;
    top: 0;
    background-color: #fff;
    display: inline-block;
    align-items: center;
    min-width: 100% !important;
    padding: 0 10% 0 10%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  }
  .ContactForm_mobile-dropdown__9CQjN a {
    font-size: 18px;
  }
  .ContactForm_mobile-dropdown__9CQjN li a {
    font-size: 15px;
  }
  .ContactForm_dropdown-hover__2oMGO:hover .ContactForm_dropdown__1qz7k {
    display: none;
  }
  ul .ContactForm_dropdown__1qz7k {
    display: block;
  }
  .ContactForm_nav-links__1QIPx li ul.ContactForm_dropdown__1qz7k {
    padding-top: 0px;
  }
  .ContactForm_hidden-arrow__1wK6S:before {
    content: '\f104';
  }
  .ContactForm_article-share__1YINt {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b355d;
    height: calc(18vh + 70px);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 70px;
    bottom: 0;
    left: 0;
  }
  .ContactForm_article-share__1YINt span {
    display: inline-block;
    color: #e3e3e3;
    text-align: center;
    font-size: 14px;
    margin: 0 5px;
  }
  .ContactForm_article-share-mob-icon__2Utea {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
  }
  .ContactForm_contact-form-header__12HTE img {
    height: 80px;
    width: 80px;
  }
  .ContactForm_large-screen__1wOJA {
    display: none !important;
  }
  .ContactForm_mob__10UAd {
    display: flex;
  }

  .ContactForm_icon__1TiGo {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
  .ContactForm_contact-form__osuFe {
    bottom: 3%;
    right: 5%;
  }
  .ContactForm_contact-form-header-subtitle__LlXO1 {
    padding: 0px 10% 0px 10%;
  }
  .ContactForm_contact-form-icon__3HcUH {
    display: block;
    width: 70px;
    height: 70px;
    cursor: pointer;
    background-size: cover;
  }
  .ContactForm_form-icon__3njFh {
    background-image: url(/static/media/chat-mobile.82dc8e1f.svg);
  }
  .ContactForm_form-close__3l4oy {
    display: inline-block;
    position: fixed;
    top: 5%;
    right: 5%;
    cursor: pointer;
    height: 35px;
    width: 35px;
  }
  .ContactForm_contact-form-header__12HTE img {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .ContactForm_contact-form-block__2Llff {
    z-index: 9999;
    top: 0;
    right: 0;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
  .ContactForm_contact-form-container__1jGat {
    padding: 4vh 5% 15px 5%;
    text-align: center;
  }
  .ContactForm_contact-form-header__12HTE {
    flex-direction: column;
  }
  .ContactForm_success-checkmark__15seI {
    margin: 50% auto 2em auto;
  }
  .ContactForm_FIO-section__8gmn2 {
    flex-direction: column;
  }
  .ContactForm_birthdayDate-faculty-section__2Hn-j {
    flex-direction: column;
  }
  .ContactForm_fio-block__3ZOhc {
    width: 100%;
    position: relative;
    margin-right: 0em;
    margin-bottom: 1em;
  }
  .ContactForm_document-block__3ZINQ {
    width: 98%;
    position: relative;
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .ContactForm_birthdayDate-faculty-section__2Hn-j .ContactForm_case-point__2ITID {
    width: 100%;
  }
  .ContactForm_university-section__1Wjeo .ContactForm_case-block__3eyLW {
    flex-direction: column;
    align-items: center;
  }
  .ContactForm_document-section__1KSOz {
    display: block;
  }
  .ContactForm_document-block__3ZINQ {
    margin-right: 0.5em;
  }
}

.ContactForm_nav-active__28EEP {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.ContactForm_textarea-section__3GAEF textarea {
  border: 1.2px solid #93a1e9;
  border-radius: 4px;
  font-size: 15px;
  min-height: 120px;
  width: 100%;
  padding: 15px 23px 5px 23px;
  color: #2d418c;
}

.ContactForm_textarea-section__3GAEF textarea:focus {
  outline: #2d418c !important;
  border: 1.2px solid #2d418c !important;
}

.ContactForm_success-checkmark__15seI {
  display: block;
  width: 80px;
  height: 155px;
  margin: 2em auto;
}
.ContactForm_success-message__2eG9P {
  position: absolute;
  display: inline-block;
  text-align: center;
  width: 80%;
  margin: 15px 10% 15px 10%;
  font-size: 16px;
  font-weight: bold;
  color: #4caf50;
  left: 0;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ::before {
  top: 3px;
  left: -2px;
  width: 30px;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ::after {
  top: 0;
  left: 30px;
  width: 60px;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  -webkit-animation: ContactForm_rotate-circle__1k-a9 4.25s ease-in;
          animation: ContactForm_rotate-circle__1k-a9 4.25s ease-in;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ::before,
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ::after {
  content: '';
  height: 100px;
  position: absolute;
  background: #ffffff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ .ContactForm_icon-line__29TC2 {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ .ContactForm_icon-line__29TC2.ContactForm_line-tip__3lkGH {
  top: 46px;
  left: 14px;
  width: 25px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: ContactForm_icon-line-tip__3Hcoz 0.75s;
          animation: ContactForm_icon-line-tip__3Hcoz 0.75s;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ .ContactForm_icon-line__29TC2.ContactForm_line-long__3Dwj5 {
  top: 38px;
  right: 8px;
  width: 47px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation: ContactForm_icon-line-long__lE9AG 0.75s;
          animation: ContactForm_icon-line-long__lE9AG 0.75s;
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ .ContactForm_icon-circle__1Xr-4 {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}
.ContactForm_success-checkmark__15seI .ContactForm_check-icon__3e3WZ .ContactForm_icon-fix__2TVth {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  background-color: #ffffff;
}

@-webkit-keyframes ContactForm_navLinkFade__1uOyG {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes ContactForm_navLinkFade__1uOyG {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes ContactForm_rotate-circle__1k-a9 {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}

@keyframes ContactForm_rotate-circle__1k-a9 {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
}
@-webkit-keyframes ContactForm_icon-line-tip__3Hcoz {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes ContactForm_icon-line-tip__3Hcoz {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@-webkit-keyframes ContactForm_icon-line-long__lE9AG {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes ContactForm_icon-line-long__lE9AG {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.ContactForm_toggle__2i_jL .ContactForm_line1__2ge8a {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
          transform: rotate(-45deg) translate(-5px, 6px);
}

.ContactForm_toggle__2i_jL .ContactForm_line2__2jytM {
  opacity: 0;
}

.ContactForm_toggle__2i_jL .ContactForm_line3__8mCiy {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
          transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 870px) {
  .ContactForm_success-checkmark__15seI {
    margin: 50% auto 2em auto;
  }
}

footer{
    display: inline-block;
    min-height: 8vh;
    padding: 20px 8% 20px 8%;
    width: 100%;
    background-color: #080d24;
}

.Footer_container-block__12687 {
    display: flex;
    position: relative;
    min-height: auto;
    padding: 50px 35px;
    width: 100%;
    overflow: hidden;
}

.Footer_copyright-part__qxIAP{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
    text-align: center;
    min-height: 5vh;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding-left: 3%;
    padding-right: 3%;
}

.Footer_contact-info__37pOA {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-height: 400px;
    padding: 0px 25px;
}

.Footer_contact-info__37pOA span:first-child{
    color: #fff;
    font-family: "Playfair Display", serif;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.Footer_contact-info__37pOA span:nth-child(2){
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: #cdd5ff;
    font-family: "Open Sans", sans-serif;
}

.Footer_contact-info__37pOA span p a{
    color: #ffcc3e;
    font-weight: 300;
}
.Footer_contact-info__37pOA span p a:hover{
    text-decoration: underline;
}
.Footer_footer-map__AaN2M{
    width:60%;
    position: relative;
    overflow: hidden;
}

@media screen and (max-width:1365px){
    .Footer_footer-map__AaN2M{
        height: 350px;
    }
    .Footer_contact-info__37pOA {
        width: 25%;
        min-height: 400px;
        padding: 0px 10px;
    }
}

@media screen and (max-width:1255px){
    footer{
        padding: 20px 5% 20px 5%;
    }
}

@media screen and (max-width:980px){
    footer {
        padding: 15px 0px 15px 0px;
    }
    footer .Footer_container-block__12687{
        flex-direction: column;
    }
    .Footer_contact-info__37pOA {
        width: 100%;
        min-height: auto;
        padding: 25px 0px 25px 0px;
        text-align: center;
    }
    .Footer_footer-map__AaN2M {
        width: 110%;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: -5%;
    }
}

@media screen and (max-width:1210px){
    .Footer_container-block__12687{
        padding: 20px 5%;
    }
}
nav {
  z-index: 222;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  min-height: 4.5em;
  background-color: #fffffffa;
  font-family: 'Open Sans', sans-serif;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: 0px 0px 8px 0px #00000026;
}

.header-logo {
  display: inline-block;
  font-family: 'Playfair Display', serif;
}

.header-logo a {
  text-decoration: none;
}

.header-logo a span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 22px;
  color: #000000;
}

.header-logo a img {
  height: 55px;
  width: 55px;
  margin-left: 5px;
  margin-right: 5px;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  margin-top: 0;
  margin-bottom: 0;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  position: relative;
  z-index: 3;
  color: #000000;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
}

.nav-links a:hover {
  color: #2d418c;
}

.nav-links button {
  position: relative;
  z-index: 3;
  color: #000000;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  border: none;
  background-color: #ffffff00;
}

.nav-links button:hover {
  color: #2d418c;
}

.nav-button {
  border: 1.2px solid #cdd5ff;
  background: #cdd5ff;
  border-radius: 4px;
  padding: 12px 16px;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 0px 8px 0px #00000026;
  -webkit-animation: slide-down 0.8s;
          animation: slide-down 0.8s;
  z-index: 999;
}

ul {
  padding: 0;
  list-style: none;
}
ul li {
  display: inline-block;
  position: relative;
  line-height: 21px;
  text-align: left;
}
.nav-links ul li a {
  display: block;
  padding: 8px 25px 8px 25px;
  color: #4d4d4d;
  background-color: #f5f6ff;
  text-decoration: none;
}
.nav-links ul li a:hover {
  color: #2d418c;
  background: #c7d1ff;
}
.nav-links li ul.dropdown {
  min-width: 100%;
  height: 100%;
  background: none;
  display: none;
  position: absolute;
  z-index: 9999;
  padding-top: 2.8vh;
  left: 0;
}

.nav-links li ul.dropdown li:last-child {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 0px;
}

.dropdown-hover:hover .dropdown {
  display: block;
}

.nav-links li ul.dropdown li {
  display: block;
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.body-shadow {
  display: block;
  position: fixed;
  z-index: 6;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #00000069;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
}

.priority {
  border-color: #b3c0ff;
  background-color: #ffffff !important;
}

.priority:hover {
  background: #b3c0ff !important;
}

.nav-button:hover {
  background: #b3c0ff;
}

.fa-angle-down,
.fa-sign-in {
  margin-left: 5px;
}

.priority .fa-sign-in:hover {
  color: #93a1e9;
}

.fa-sign-in:hover {
  color: #93a1e9 !important;
}

.fa-sign-in {
  color: #b3c0ff;
}

.fa-lock {
  color: #b3c0ff;
  margin: 0 8px;
}

.fa-lock:hover {
  color: #93a1e9;
}

.burger {
  display: none;
  cursor: pointer;
  margin-right: 25px;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #2d418c;
  margin: 5px;
  transition: all 0.3s ease;
}

.responsive-login-button {
  display: block;
}

.responsive-login-button a {
  color: #b3c0ff;
}

.responsive-login-button .fa-sign-in {
  display: inline-block;
}

.mob {
  display: none;
}
.large-screen {
  display: block;
}

.icon {
  display: block;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-size: cover;
}

@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@media screen and (max-width: 1556px) {
  .nav-links {
    width: 50%;
  }
}

@media screen and (max-width: 1240px) {
  .responsive-login-button {
    display: inline-block;
  }
  .nav-button {
    display: none;
  }
  .nav-links {
    width: 60%;
  }
  .large-screen {
    display: none;
  }
}

@media screen and (max-width: 998px) {
  .nav-links {
    width: 70%;
  }
}

@media screen and (max-width: 870px) {
  body {
    overflow-x: hidden;
  }
  nav {
    min-height: 60px;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }
  .nav-links {
    position: fixed;
    z-index: 111;
    height: calc(100vh - 60px);
    top: 60px;
    right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 80%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
    border-top: 1.2px solid #2d418c;
  }
  .nav-links li {
    margin: 18px 8% 18px 8%;
  }
  .nav-links ul li a {
    padding: 10px 25px 10px 2%;
  }
  .nav-links li ul.dropdown li:last-child {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 1em;
  }
  .burger {
    display: block;
    margin-right: 0;
  }
  .fa-angle-down:before {
    content: '\f105';
  }
  .mobile-dropdown {
    position: fixed;
    z-index: 6666;
    height: 100vh;
    top: 0;
    background-color: #fff;
    display: inline-block;
    align-items: center;
    min-width: 100% !important;
    padding: 0 10% 0 10%;
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  }
  .mobile-dropdown .dropdown {
    display: block !important;
  }
  .mobile-dropdown a {
    font-size: 18px;
  }
  .mobile-dropdown li a {
    font-size: 15px;
  }
  .dropdown-hover:hover .dropdown {
    display: none;
  }
  ul .dropdown {
    display: block;
  }
  .nav-links li ul.dropdown {
    padding-top: 0px;
  }
  .hidden-arrow:before {
    content: '\f104';
  }
  .article-share {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b355d;
    height: calc(18vh + 70px);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 70px;
    bottom: 0;
    left: 0;
  }
  .article-share li span a {
    background-color: #2d418c;
  }
  .large-screen {
    display: none !important;
  }
  .mob {
    display: flex;
  }
  .menu-scroll {
    overflow-y: hidden !important;
  }
  .icon {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
  .contact-form {
    bottom: 3%;
    right: 5%;
  }
  .article-share-mob-icon {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
  }
  .article-share span {
    display: inline-block;
    color: #e3e3e3;
    text-align: center;
    font-size: 14px;
    margin: 0 5px;
  }
}

.nav-active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

@-webkit-keyframes navLinkFade {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.toggle .line1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
          transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
          transform: rotate(45deg) translate(-5px, -6px);
}

.Loader_loaderContainer__19ipN {
    position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 100vh;
}
.Loader_loader__1j2Bs {
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 2px;
    width: 100px;
    height: 100px;
}
.Loader_loader__1j2Bs > div {
    position: relative;
    width: 100%;
    height: 100%;
    background: #2D418C;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation: Loader_loader__1j2Bs 2s infinite linear;
            animation: Loader_loader__1j2Bs 2s infinite linear;
}
.Loader_loader__1j2Bs > div:nth-of-type(1), .Loader_loader__1j2Bs > div:nth-of-type(5), .Loader_loader__1j2Bs > div:nth-of-type(9) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
}
.Loader_loader__1j2Bs > div:nth-of-type(4), .Loader_loader__1j2Bs > div:nth-of-type(8) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}
.Loader_loader__1j2Bs > div:nth-of-type(2), .Loader_loader__1j2Bs > div:nth-of-type(6) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
}
.Loader_loader__1j2Bs > div:nth-of-type(3) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}
@-webkit-keyframes Loader_loader__1j2Bs {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    80% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
}
@keyframes Loader_loader__1j2Bs {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    80% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
}

.PageLoader_loaderContainer__oL9bb {
    position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 100%;
}
.PageLoader_loader__1IX8y {
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 2px;
    width: 50px;
    height: 50px;
}
.PageLoader_loader__1IX8y > div {
    position: relative;
    width: 100%;
    height: 100%;
    background: #2D418C;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation: PageLoader_loader__1IX8y 2s infinite linear;
            animation: PageLoader_loader__1IX8y 2s infinite linear;
}
.PageLoader_loader__1IX8y > div:nth-of-type(1), .PageLoader_loader__1IX8y > div:nth-of-type(5), .PageLoader_loader__1IX8y > div:nth-of-type(9) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
}
.PageLoader_loader__1IX8y > div:nth-of-type(4), .PageLoader_loader__1IX8y > div:nth-of-type(8) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}
.PageLoader_loader__1IX8y > div:nth-of-type(2), .PageLoader_loader__1IX8y > div:nth-of-type(6) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
}
.PageLoader_loader__1IX8y > div:nth-of-type(3) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}
@-webkit-keyframes PageLoader_loader__1IX8y {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    80% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
}
@keyframes PageLoader_loader__1IX8y {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    80% {
        -webkit-transform: scale(1);
                transform: scale(1);
   }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
   }
}

.SidebarPanel_article-share__M4WGX {
  position: fixed;
  z-index: 444;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 1.5vh;
  display: flex;
  flex-direction: column;
}
.SidebarPanel_mob__2n8xc {
  display: none;
}

.SidebarPanel_article-share__M4WGX li span a {
  background-color: #2d418c;
}

.SidebarPanel_large-screen__gcTGp {
  display: block;
}

.SidebarPanel_article-share__M4WGX span {
  margin-bottom: 5px;
}

.SidebarPanel_contact-form-icon__2p7SN {
  display: block;
  width: 54px;
  height: 54px;
  cursor: pointer;
  background-size: cover;
}

.SidebarPanel_contact-form__3nBbT {
  display: block !important;
  position: fixed;
  z-index: 100;
  bottom: calc(58vh - 55px);
  right: 1vh;
  height: 54px;
  width: 54px;
  text-align: center;
}
.SidebarPanel_contact-form-header__1Yfan img {
  height: 65px;
  width: 65px;
  margin-right: 10px;
}
.SidebarPanel_contact-form-block__2-hfu {
  position: fixed;
  display: none;
  z-index: 444;
  bottom: calc(13vh - 80px);
  right: 1.5vh;
  height: auto;
  width: 350px;
  background-color: #fff;
  border-radius: 50px 5px 50px 5px;
  -webkit-animation: SidebarPanel_slide-left__16srS 0.6s;
          animation: SidebarPanel_slide-left__16srS 0.6s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 8px;
}

.SidebarPanel_contact-form-container__2D456 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 25px 15px 25px;
}

.SidebarPanel_contact-form-header__1Yfan {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.SidebarPanel_contact-form-header__1Yfan span {
  font-size: 18px;
  margin: 0 0;
  color: #000000;
}

.SidebarPanel_contact-form-header-subtitle__KvbGT {
  font-size: 15px;
  color: #a1a1a1;
  margin: 10px 0 10px 0;
}

.SidebarPanel_contact-form-body__13CNR {
  margin: 15px 0px 10px 0px;
  position: relative;
}

.SidebarPanel_form-group__ZyTQh {
  margin-bottom: 1.5rem;
  position: relative;
}

.SidebarPanel_form-group__ZyTQh input {
  border: 1.2px solid #93a1e9;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  padding: 15px 23px 5px 23px;
  text-transform: lowercase;
}

.SidebarPanel_form-group__ZyTQh input:focus {
  border: 1.2px solid #2d418c;
}

.SidebarPanel_form-group__ZyTQh label {
  position: absolute;
  text-align: left;
  bottom: 0px;
  left: 15px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  pointer-events: none;
}

.SidebarPanel_form-group__ZyTQh label span {
  color: #93a1e9;
  padding: 0px 8px;
  margin: 0px 0px 0px 0px;
  background-color: #fff;
}

.SidebarPanel_form-group__ZyTQh label::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.SidebarPanel_content-name__q0gcT {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease 0s;
}

.SidebarPanel_form-group__ZyTQh input:focus + .SidebarPanel_label-name__3y6Hg .SidebarPanel_content-name__q0gcT,
.SidebarPanel_form-group__ZyTQh input:valid + .SidebarPanel_label-name__3y6Hg .SidebarPanel_content-name__q0gcT {
  color: #2d418c;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.SidebarPanel_form-icon__1p2OL {
  background-image: url(/static/media/chat.68457e6d.svg);
}
.SidebarPanel_form-icon__1p2OL:hover {
  opacity: 0.85;
}

@media screen and (max-width: 870px) {
  body {
    overflow-x: hidden;
  }
  nav {
    min-height: 60px;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }
  .SidebarPanel_nav-links__sCsWJ {
    position: fixed;
    z-index: 111;
    height: calc(100vh - 60px);
    top: 60px;
    right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 80%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
    border-top: 1.2px solid #2d418c;
  }
  .SidebarPanel_nav-links__sCsWJ li {
    opacity: 0;
    margin: 18px 8% 18px 8%;
  }
  .SidebarPanel_nav-links__sCsWJ ul li a {
    padding: 10px 25px 10px 2%;
  }
  .SidebarPanel_nav-links__sCsWJ li ul.SidebarPanel_dropdown__3TKT- li:last-child {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 1em;
  }
  .SidebarPanel_burger__2n0xZ {
    display: block;
    margin-right: 0;
  }
  .SidebarPanel_fa-angle-down__3kmFC:before {
    content: '\f105';
  }
  .SidebarPanel_mobile-dropdown__agzA7 {
    position: fixed;
    z-index: 6666;
    height: 100vh;
    top: 0;
    background-color: #fff;
    display: inline-block;
    align-items: center;
    min-width: 100% !important;
    padding: 0 10% 0 10%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  }
  .SidebarPanel_mobile-dropdown__agzA7 a {
    font-size: 18px;
  }
  .SidebarPanel_mobile-dropdown__agzA7 li a {
    font-size: 15px;
  }
  .SidebarPanel_dropdown-hover__25I-k:hover .SidebarPanel_dropdown__3TKT- {
    display: none;
  }
  ul .SidebarPanel_dropdown__3TKT- {
    display: block;
  }
  .SidebarPanel_nav-links__sCsWJ li ul.SidebarPanel_dropdown__3TKT- {
    padding-top: 0px;
  }
  .SidebarPanel_hidden-arrow__2fv1u:before {
    content: '\f104';
  }
  .SidebarPanel_article-share__M4WGX {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b355d;
    height: calc(18vh + 70px);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 70px;
    bottom: 0;
    left: 0;
  }
  .SidebarPanel_article-share__M4WGX span {
    display: inline-block;
    color: #e3e3e3;
    text-align: center;
    font-size: 14px;
    margin: 0 5px;
  }
  .SidebarPanel_article-share-mob-icon__RaA5s {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
  }
  .SidebarPanel_contact-form-header__1Yfan img {
    height: 80px;
    width: 80px;
  }
  .SidebarPanel_large-screen__gcTGp {
    display: none !important;
  }
  .SidebarPanel_mob__2n8xc {
    display: flex;
  }

  .SidebarPanel_icon__1LpxY {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
  .SidebarPanel_contact-form__3nBbT {
    bottom: 3%;
    right: 5%;
  }
  .SidebarPanel_contact-form-header-subtitle__KvbGT {
    padding: 0px 10% 0px 10%;
  }
  .SidebarPanel_contact-form-icon__2p7SN {
    display: block;
    width: 70px;
    height: 70px;
    cursor: pointer;
    background-size: cover;
  }
  .SidebarPanel_form-icon__1p2OL {
    background-image: url(/static/media/chat-mobile.82dc8e1f.svg);
  }
  .SidebarPanel_form-close__3VtEG {
    display: inline-block;
    position: fixed;
    top: 5%;
    right: 5%;
    cursor: pointer;
    height: 35px;
    width: 35px;
  }
  .SidebarPanel_contact-form-header__1Yfan img {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .SidebarPanel_contact-form-block__2-hfu {
    z-index: 9999;
    top: 0;
    right: 0;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
  .SidebarPanel_contact-form-container__2D456 {
    padding: 4vh 5% 15px 5%;
    text-align: center;
  }
  .SidebarPanel_contact-form-header__1Yfan {
    flex-direction: column;
  }
  .SidebarPanel_submit-form-btn__30-xx {
    display: inline-block;
    position: relative;
    width: 11rem;
    left: 0;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 16px;
    border: none;
    background-color: #2d418c;
    margin-top: 5vh;
  }
  .SidebarPanel_success-checkmark__39df- {
    margin: 50% auto 2em auto;
  }
  .SidebarPanel_FIO-section__3PWUA {
    flex-direction: column;
  }
  .SidebarPanel_birthdayDate-faculty-section__3-zv3 {
    flex-direction: column;
  }
  .SidebarPanel_fio-block__2aRDH {
    width: 100%;
    position: relative;
    margin-right: 0em;
    margin-bottom: 1em;
  }
  .SidebarPanel_document-block__2de71 {
    width: 98%;
    position: relative;
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .SidebarPanel_birthdayDate-faculty-section__3-zv3 .SidebarPanel_case-point__3SDq4 {
    width: 100%;
  }
  .SidebarPanel_university-section__3vezq .SidebarPanel_case-block__3arnr {
    flex-direction: column;
    align-items: center;
  }
  .SidebarPanel_document-section__2trb7 {
    display: block;
  }
  .SidebarPanel_document-block__2de71 {
    margin-right: 0.5em;
  }
}

.SidebarPanel_nav-active__3Eh4N {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

@-webkit-keyframes SidebarPanel_navLinkFade__3dLvF {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes SidebarPanel_navLinkFade__3dLvF {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.SidebarPanel_toggle__w3WXx .SidebarPanel_line1__IKKWI {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
          transform: rotate(-45deg) translate(-5px, 6px);
}

.SidebarPanel_toggle__w3WXx .SidebarPanel_line2__3zwbW {
  opacity: 0;
}

.SidebarPanel_toggle__w3WXx .SidebarPanel_line3__3QGhr {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
          transform: rotate(45deg) translate(-5px, -6px);
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

