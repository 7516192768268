.article-share {
  position: fixed;
  z-index: 444;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5vh;
  display: flex;
  flex-direction: column;
}
.mob {
  display: none;
}

.article-share li span a {
  background-color: #2d418c;
}

.large-screen {
  display: block;
}

.article-share span {
  margin-bottom: 5px;
}

.contact-form-icon {
  display: block;
  width: 54px;
  height: 54px;
  cursor: pointer;
  background-size: cover;
}

.contact-form {
  display: block !important;
  position: fixed;
  z-index: 100;
  bottom: calc(58vh - 55px);
  right: 1vh;
  height: 54px;
  width: 54px;
  text-align: center;
}
.contact-form-header img {
  height: 65px;
  width: 65px;
  margin-right: 10px;
}
.contact-form-block {
  position: fixed;
  display: none;
  z-index: 444;
  bottom: calc(13vh - 80px);
  right: 1.5vh;
  height: auto;
  width: 350px;
  background-color: #fff;
  border-radius: 50px 5px 50px 5px;
  animation: slide-left 0.6s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 8px;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 25px 15px 25px;
}

.contact-form-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.contact-form-header span {
  font-size: 18px;
  margin: 0 0;
  color: #000000;
}

.contact-form-header-subtitle {
  font-size: 15px;
  color: #a1a1a1;
  margin: 10px 0 10px 0;
}

.contact-form-body {
  margin: 15px 0px 10px 0px;
  position: relative;
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}

.form-group input {
  border: 1.2px solid #93a1e9;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  padding: 15px 23px 5px 23px;
  text-transform: lowercase;
}

.form-group input:focus {
  border: 1.2px solid #2d418c;
}

.form-group label {
  position: absolute;
  text-align: left;
  bottom: 0px;
  left: 15px;
  width: 100%;
  height: 100%;
  font-size: 15px;
  pointer-events: none;
}

.form-group label span {
  color: #93a1e9;
  padding: 0px 8px;
  margin: 0px 0px 0px 0px;
  background-color: #fff;
}

.form-group label::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease 0s;
}

.form-group input:focus + .label-name .content-name,
.form-group input:valid + .label-name .content-name {
  color: #2d418c;
  transform: translateY(-100%);
}

.form-icon {
  background-image: url('assets/images/social-media-icons/chat.svg');
}
.form-icon:hover {
  opacity: 0.85;
}

@media screen and (max-width: 870px) {
  body {
    overflow-x: hidden;
  }
  nav {
    min-height: 60px;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
  }
  .nav-links {
    position: fixed;
    z-index: 111;
    height: calc(100vh - 60px);
    top: 60px;
    right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 80%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    border-top: 1.2px solid #2d418c;
  }
  .nav-links li {
    opacity: 0;
    margin: 18px 8% 18px 8%;
  }
  .nav-links ul li a {
    padding: 10px 25px 10px 2%;
  }
  .nav-links li ul.dropdown li:last-child {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 1em;
  }
  .burger {
    display: block;
    margin-right: 0;
  }
  .fa-angle-down:before {
    content: '\f105';
  }
  .mobile-dropdown {
    position: fixed;
    z-index: 6666;
    height: 100vh;
    top: 0;
    background-color: #fff;
    display: inline-block;
    align-items: center;
    min-width: 100% !important;
    padding: 0 10% 0 10%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  }
  .mobile-dropdown a {
    font-size: 18px;
  }
  .mobile-dropdown li a {
    font-size: 15px;
  }
  .dropdown-hover:hover .dropdown {
    display: none;
  }
  ul .dropdown {
    display: block;
  }
  .nav-links li ul.dropdown {
    padding-top: 0px;
  }
  .hidden-arrow:before {
    content: '\f104';
  }
  .article-share {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b355d;
    height: calc(18vh + 70px);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 70px;
    bottom: 0;
    left: 0;
  }
  .article-share span {
    display: inline-block;
    color: #e3e3e3;
    text-align: center;
    font-size: 14px;
    margin: 0 5px;
  }
  .article-share-mob-icon {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
  }
  .contact-form-header img {
    height: 80px;
    width: 80px;
  }
  .large-screen {
    display: none !important;
  }
  .mob {
    display: flex;
  }

  .icon {
    display: block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-size: cover;
  }
  .contact-form {
    bottom: 3%;
    right: 5%;
  }
  .contact-form-header-subtitle {
    padding: 0px 10% 0px 10%;
  }
  .contact-form-icon {
    display: block;
    width: 70px;
    height: 70px;
    cursor: pointer;
    background-size: cover;
  }
  .form-icon {
    background-image: url('assets/images/social-media-icons/chat-mobile.svg');
  }
  .form-close {
    display: inline-block;
    position: fixed;
    top: 5%;
    right: 5%;
    cursor: pointer;
    height: 35px;
    width: 35px;
  }
  .contact-form-header img {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .contact-form-block {
    z-index: 9999;
    top: 0;
    right: 0;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
  .contact-form-container {
    padding: 4vh 5% 15px 5%;
    text-align: center;
  }
  .contact-form-header {
    flex-direction: column;
  }
  .submit-form-btn {
    display: inline-block;
    position: relative;
    width: 11rem;
    left: 0;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 16px;
    border: none;
    background-color: #2d418c;
    margin-top: 5vh;
  }
  .success-checkmark {
    margin: 50% auto 2em auto;
  }
  .FIO-section {
    flex-direction: column;
  }
  .birthdayDate-faculty-section {
    flex-direction: column;
  }
  .fio-block {
    width: 100%;
    position: relative;
    margin-right: 0em;
    margin-bottom: 1em;
  }
  .document-block {
    width: 98%;
    position: relative;
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .birthdayDate-faculty-section .case-point {
    width: 100%;
  }
  .university-section .case-block {
    flex-direction: column;
    align-items: center;
  }
  .document-section {
    display: block;
  }
  .document-block {
    margin-right: 0.5em;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
