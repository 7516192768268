html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  border-style: none;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #f1f4ff;
}

::-webkit-scrollbar-thumb {
  background: #cdd5ff;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3c0ff;
}

*,
::after,
::before {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  background-color: transparent;
}

body {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title-container {
  text-align: center;
  padding: 50px 0 15px 0;
  margin-bottom: 20px;
}

.title-container span:first-child {
  display: inline-block;
  width: 100%;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
}

.title-container span:nth-child(2) {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: #000;
}

.wrap-login100 {
  position: relative;
  border-bottom: 2px solid #b3c0ff;
  margin-top: 25px;
  margin-bottom: 25px;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #b3c0ff;
  margin-bottom: 45px;
}

.input100 {
  font-size: 18px;
  color: #ffffff;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 52px;
  background: transparent;
  padding: 0 5px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #ffffff;
}

.focus-input100::after {
  font-size: 18px;
  color: #b3c0ff;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

@media screen and (max-width: 586px) {
  .title-container {
    padding-bottom: 2em;
    padding-top: 2em;
    text-align: center;
  }
  .title-container span:first-child {
    font-size: 30px;
    line-height: 48px;
  }
  .title-container span:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
  }
}
